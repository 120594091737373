<template>
  <div id="plantPressure">
    <el-dialog
      :title="plantPressureFormTitle"
      width="1500px"
      :visible.sync="plantPressureDialogVisible"
      :close-on-click-modal="false"
      @close="plantPressureDialogClose"
    >
      <el-form
        ref="plantPressureFormRef"
        :model="plantPressureForm"
        :rules="plantPressureFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="年度" prop="year">
              <el-input v-model="plantPressureForm.year" placeholder="请输入年度" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="月份" prop="month">
              <el-input v-model="plantPressureForm.month" placeholder="请输入月份" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-toolbar
            v-if="plantPressureFormTitle === '新增厂房压差记录表'
              || plantPressureFormTitle === '修改厂房压差记录表'"
          >
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="pressureDetailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="operationDate"
              title="日期"
              :edit-render="{name: '$input', props: {type: 'date', clearable: true}}"
            />
            <vxe-table-column
              field="pressureGauge1"
              title="XY-J-075(女二更)"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="pressureGauge2"
              title="XY-J-076(男二更)"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="pressureGauge3"
              title="XY-J-077(缓冲间)"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="pressureGauge4"
              title="XY-J-078(缓冲间)"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="pressureGauge5"
              title="XY-J-079(组装车间)"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="pressureGauge6"
              title="XY-J-080(组装车间)"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column field="result" title="监测结果" width="150">
              <template v-slot="{row,column}">
                <vxe-radio-group v-model="row[column.property]">
                  <vxe-radio :label="1" content="合格" />
                  <vxe-radio :label="2" content="不合格" />
                </vxe-radio-group>
              </template>
            </vxe-table-column>
            <vxe-table-column
              v-if="plantPressureFormTitle === '新增厂房压差记录表'
                || plantPressureFormTitle === '修改厂房压差记录表'"
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="plantPressureDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="plantPressureFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="日期">
        <el-date-picker v-model="searchForm.recordDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="plantPressurePage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="year" label="年度" />
      <el-table-column prop="month" label="月份" />
      <el-table-column prop="recorder" label="记录人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.recordDate">{{ scope.row.recordDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="plantPressurePage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addPlantPressure,
  deletePlantPressure,
  updatePlantPressure,
  selectPlantPressureInfo,
  selectPlantPressureList
} from '@/api/produce/plantPressure'

export default {
  data () {
    return {
      plantPressureDialogVisible: false,
      plantPressureFormTitle: '',
      plantPressureForm: {
        id: '',
        year: '',
        month: '',
        pressureDetailJson: ''
      },
      plantPressureFormRules: {
        recordDate: [{ required: true, message: '日期不能为空', trigger: ['blur', 'change']}]
      },
      plantPressurePage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        recordDate: ''
      },
      pressureDetailList: []
    }
  },
  created () {
    selectPlantPressureList(this.searchForm).then(res => {
      this.plantPressurePage = res
    })
  },
  methods: {
    plantPressureDialogClose () {
      this.$refs.plantPressureFormRef.resetFields()
      this.pressureDetailList = []
    },
    plantPressureFormSubmit () {
      if (this.plantPressureFormTitle === '厂房压差记录表详情') {
        this.plantPressureDialogVisible = false
        return
      }
      this.$refs.plantPressureFormRef.validate(async valid => {
        if (valid) {
          this.plantPressureForm.pressureDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.plantPressureFormTitle === '新增厂房压差记录表') {
            this.plantPressureForm.id = ''
            await addPlantPressure(this.plantPressureForm)
          } else if (this.plantPressureFormTitle === '修改厂房压差记录表') {
            await updatePlantPressure(this.plantPressureForm)
          }
          this.plantPressurePage = await selectPlantPressureList(this.searchForm)
          this.plantPressureDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.plantPressureFormTitle = '新增厂房压差记录表'
      this.plantPressureDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deletePlantPressure(row.id)
        if (this.plantPressurePage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.plantPressurePage = await selectPlantPressureList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.plantPressureFormTitle = '修改厂房压差记录表'
      this.plantPressureDialogVisible = true
      this.selectPlantPressureInfoById(row.id)
    },
    handleInfo (index, row) {
      this.plantPressureFormTitle = '厂房压差记录表详情'
      this.plantPressureDialogVisible = true
      this.selectPlantPressureInfoById(row.id)
    },
    selectPlantPressureInfoById (id) {
      selectPlantPressureInfo(id).then(res => {
        this.plantPressureForm.id = res.id
        this.plantPressureForm.year = res.year
        this.plantPressureForm.month = res.month
        this.pressureDetailList = res.pressureDetailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectPlantPressureList(this.searchForm).then(res => {
        this.plantPressurePage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectPlantPressureList(this.searchForm).then(res => {
        this.plantPressurePage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectPlantPressureList(this.searchForm).then(res => {
        this.plantPressurePage = res
      })
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    }
  }
}
</script>

<style>
</style>
