import axios from '@/common/axios'
import qs from 'qs'

export function addPlantPressure (data) {
  return axios({
    method: 'post',
    url: '/production/plantPressure/add',
    data: qs.stringify(data)
  })
}

export function deletePlantPressure (id) {
  return axios({
    method: 'delete',
    url: '/production/plantPressure/delete/' + id
  })
}

export function updatePlantPressure (data) {
  return axios({
    method: 'put',
    url: '/production/plantPressure/update',
    data: qs.stringify(data)
  })
}

export function selectPlantPressureInfo (id) {
  return axios({
    method: 'get',
    url: '/production/plantPressure/info/' + id
  })
}

export function selectPlantPressureList (query) {
  return axios({
    method: 'get',
    url: '/production/plantPressure/list',
    params: query
  })
}
